import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_USERS_COMPANY } from '../../queries';
import { companyVar } from './apolloVars';


// Define the shape of the data returned by the GET_USERS_COMPANY query
export interface GetUsersCompanyData {
  company: {
    id: string;
    currency: string;
    discount: number;
    environment: string;
    responsiblePerson: {
      name: string;
      email: string;
      phoneNumber: string;
      UseCategoriesReturn };
    paymentMethods: string[]; // Assuming it's an array of strings
    vatRate: number;
  };
}

export default function useCompany() {
  const company = useReactiveVar(companyVar);

  return {
    id: company?.id,
    currency: company?.currency,
    discount: company?.discount,
    environment: company?.environment,
    responsiblePerson: company?.responsiblePerson,
    paymentMethods: company?.paymentMethods,
    vatRate: company?.vatRate,
    addCompany(company) {
      companyVar(company);
    }
  };
}
