import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";

// APOLLO
import { REMOVE_FROM_CART_MUTATION } from "../../lib/apollo/mutations";
import useCartItems from "../../lib/apollo/storage/appConfig/useCartItems";
import useActiveOrder from "../../lib/apollo/storage/appConfig/useActiveOrder";
import useUser from "../../lib/apollo/storage/appConfig/useUser";

// COMPONENTS
import SkuTotalPrice from "../Ui/Sku/SkuTotalPrice";

// TYPES
import {
  CartSkuProps,
  RemoveFromCartResponse,
  RemoveFromCartVars,
} from "../../types/cart";

// STYLES
import { CartSkuStyles } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../lib/apollo/storage/appConfig/useStore";
import useCompany from "../../lib/apollo/storage/appConfig/useCompany";
import AddToCartInputSku from './AddToCartInputSku';

const CartSku: React.FC<CartSkuProps> = ({
  item,
  quantity,
  price,
  orderItemId,
  productName,
  selectedSku,
  discountedBy,
}) => {
  const { addCartItems } = useCartItems();
  const { addActiveOrder } = useActiveOrder();
  const { basePrice } = useStore();
  const { currency } = useCompany();

  const [
    removeFromCart,
    { loading: removeItemLoading, data: removeFromCartData },
  ] = useMutation<RemoveFromCartResponse, RemoveFromCartVars>(
    REMOVE_FROM_CART_MUTATION,
    {
      variables: {
        orderItemId: orderItemId,
        currency,
        basePrice,
      },
    }
  );
  const removeFromCartItems = removeFromCartData?.removeFromCart?.items;
  const updatedOrder = removeFromCartData?.removeFromCart;

useEffect(() => {
    if (removeFromCartItems) {
      addCartItems(removeFromCartItems);

      if (updatedOrder?.items?.length) {
        addActiveOrder({
          id: updatedOrder.id,
          shippingCost: updatedOrder.shippingCost,
          totalPrice: updatedOrder.totalPrice,
          totalVAT: updatedOrder.totalVAT,
          discount: updatedOrder.discountedBy,
          vatRate: updatedOrder.vatRate,
        });
      }
    }

    if (removeFromCartData)
      if (
        removeFromCartItems?.length === 1 ||
        removeFromCartData?.removeFromCart?.items.length === 0
      ) {
        addActiveOrder({
          id: "",
          shippingCost: 0,
          totalPrice: 0,
          totalVAT: 0,
          discount: 0,
          vatRate: 0
        });
      }
  }, [removeFromCartData]);


  const { productsForCampaing } = useUser();
  const isInCampaign = productsForCampaing?.find(
    (product) => product.id === item.product.id
  );

  return (
    item.sku !== "0001" && (
      <CartSkuStyles className={selectedSku === productName ? "active" : ""}>
        <div className="sku-info">
          <span></span>
          Size {item.size}
          {`${item.version ? `, ${item.version}` : ""} `}
          {`${item.color ? `, ${item.color}` : ""} `}
          <span
            className="delete"
            onClick={() => !removeItemLoading && removeFromCart()}
          >
            <FontAwesomeIcon className="close-icon" icon={faTimes} />
          </span>
        </div>
        <div className="quantity-price">
          <span></span>
          <span>Quantity: {quantity}</span>
          <SkuTotalPrice
            className={`total-price ${isInCampaign ? "campaign" : ""}`}
            price={price}
            discountedBy={discountedBy}
            quantity={quantity}
          />
          <AddToCartInputSku
            orderItemId={orderItemId}
            skuId={item.id}
            receivedInventory={item.inventory}
          />
          
        </div>
      </CartSkuStyles>
    )
  );
};

export default CartSku;
