import React, {
  useState,
} from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ADD_TO_CART_MUTATION } from '@apollo-custom/mutations';
import { QuantityButton, InputSkuStyles } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import useActiveOrder from '@apollo-custom/storage/appConfig/useActiveOrder';
import useCartItems from '@apollo-custom/storage/appConfig/useCartItems';
import useStore from '@apollo-custom/storage/appConfig/useStore';
import useCompany from '@apollo-custom/storage/appConfig/useCompany';
import { getQuantityForSku } from '@utils/cartUtils';
import { Order, OrderItem } from 'src/generated/graphql';

type AddToCartInputSkuProps = {
  className?: string;
  orderItemId: string;
  tabIndex?: number;
  skuId: string;
  reason?: string;
  isListItem?: boolean;
  modalPopup?: boolean;
  shippingSku?: boolean;
  receivedInventory: number;
}

const AddToCartInputSku: React.FC<AddToCartInputSkuProps> = ({
  className,
  orderItemId,
  tabIndex,
  skuId,
  reason = '',
  isListItem,
  modalPopup = false,
  shippingSku = false,
}) => {
  const { id: orderId } = useActiveOrder();
  const receivedQuantity = getQuantityForSku(skuId);
  const [quantity, setQuantity] = useState(receivedQuantity);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

  const { addCartItems } = useCartItems();
  const { addActiveOrder } = useActiveOrder();
  const { basePrice } = useStore();
  const { currency } = useCompany();

  const [addToCart] = useMutation<{ addToCart: Order & { items: OrderItem[] } }>(ADD_TO_CART_MUTATION, {
    onCompleted: (data) => {
      const orderItems = data?.addToCart?.items;
      const order = data?.addToCart;
      if (orderItems) {
        addCartItems(orderItems);
        addActiveOrder({
          id: order.id,
          shippingCost: order.shippingCost,
          totalPrice: order.totalPrice,
          totalVAT: order.totalVAT,
          discount: order.discountedBy,
          vatRate: order.vatRate,
        });
      }
      const item = orderItems.find((orderItem) => orderItem?.item?.id === skuId);

      if (!item) {
        toast.success(`Item removed`);
      } else {
        setQuantity(item?.quantity || 0);
        if (quantity > (item?.quantity ?? 0)) {
          toast.warn(`UPDATED! ${`${item.quantity} is the maximum quantity available`}`);
        } else {
          toast.success(`Updated product quantity!`);
        }
      }
    },
    onError: (err) => {
      toast.error(`Error: ${err.message}`);
    },
  });

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);

    // Clear the existing debounce timeout
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set a new debounce timeout
    const timeoutId = setTimeout(() => {
      addToCart({
        variables: {
          cartInput: {
            skuId,
            quantity: newQuantity,
            orderId: orderId || null,
          },
          currency,
          basePrice,
        },
      });
    }, 500); // Adjust debounce delay as needed

    setDebounceTimeout(timeoutId);
  };

  return (
    <InputSkuStyles className={`${className} ${modalPopup ? 'modal' : ''}`}>
      <div className="buttons-container">
        <QuantityButton
          role="button"
          onClick={() => handleQuantityChange(Math.max(0, quantity - 1))}
        >
          <span>-1</span>
        </QuantityButton>
      </div>
      <input
        aria-label="quantity"
        type="number"
        name="quantity"
        value={quantity}
        tabIndex={tabIndex ?? 0 + 1}
        onChange={(e) => {
          const newValue = parseInt(e.target.value, 10) || 0;
          handleQuantityChange(newValue);
        }}
        onWheel={(e) => e.preventDefault()}
      />
      <div className="buttons-container">
        <QuantityButton
          role="button"
          onClick={() => handleQuantityChange(quantity + 1)}
        >
          <span>+1</span>
        </QuantityButton>
      </div>
      {(isListItem || modalPopup) && receivedQuantity !== quantity && (
        <button type="submit" className="submit-button">
          <FontAwesomeIcon icon={faCheckCircle} />
        </button>
      )}
    </InputSkuStyles>
  );
};

export default AddToCartInputSku;
