import styled from "styled-components";
import { device } from "../../utils/deviceSizes";

export const CartStyles = styled.div`
  transition: all 0.5s;

  color: #000;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgba(0, 0, 0, 0.5);
    display: none;
  }

  &.sliding-cart {
    .order-title {
      padding:0 2rem;
    }
    z-index: -1000;
    transform: translateX(150%);
    &.open {
      transform: translateX(0);
      z-index: 10000000;
    }
    max-width: unset;
    min-width: 400px;

    position: fixed !important;

    background: var(--lightGrey1);
    min-height: 100vh;

    display: block !important;
    top: 10rem;
    right: 0;

  } 

  &.mobile-cart,
  &.laptop-cart {
    display: none;
  }
  &.laptop-cart__product-page {
    display: unset;
    .cart-container {
      width: 100%;
    }
  }

  .warning-message {
    background-color: var(--lightYellow);
    color: var(--lightBrown);
    border-left: 5px solid var(--lightBrown);
    padding: 20px;
    margin: 10px;

    &__red {
      background-color: var(--lightRed);
      color: var(--red);
      border-left: 5px solid var(--red);
    }
  }

  .warning-message .message-header {
    display: flex;
    align-items: center;
  }

  .warning-message .warning-icon {
    margin-right: 10px;
  }

  .warning-message h3 {
    font-weight: bold;
  }

  .warning-message p {
    margin-top: 10px;
  }

  @media ${device.laptopL} {
    &.desktop-cart {
      display: none;
    }
    &.mobile-cart {
      display: none;
    }
    &.laptop-cart {
      display: unset !important;
    }
  }
  
  @media ${device.tablet} {
    z-index: -1000;
    transform: translateY(150%) !important;
    &.open {
      transform: translateY(0) !important;
      z-index: 10000000;
    }
    width: 100% !important;
    max-width: unset;

    position: fixed !important;

    background: var(--lightGrey1);
    min-height: 100vh !important;

    top: 8rem !important;

    &.tablet-cart {
      display: none !important;
    }
    &.mobile-cart {
      display: unset;
      /* max-height */
      /* overflow: scroll; */
    }
  }

  .order-title {
    @media ${device.tablet} {
      display: none;
    }
  }

  .cart-container {
    width: 40rem;
    height: max-content;
    background: var(--lightGrey1);

    @media ${device.laptopL} {
      width: 100%;
    }

    @media ${device.tablet} {
      padding-bottom: 15rem;
      max-height: 100%;

      .cart-product-list {
        overflow-y: scroll;
        max-height: 16rem;
      }
    }
    &__header {
      padding: 1.5rem 2.5rem;
      background: var(--lightGrey2);
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        display: none;
        text-transform: uppercase;

        @media ${device.tablet} {
          display: unset;
        }
      }
    }
  }

  .cart-product {
    padding: 1rem 2.5rem;
    display: flex;
    flex-direction: column;

    justify-content: space-between;
  }

  hr {
    margin: 2.5rem;
    &:nth-of-type(3) {
      border-top: 1px solid black;
    }
  }

  .total,
  .shipping {
    padding: 0 2.5rem;
    font-size: 1.8rem;
    display: flex;
    justify-content: space-between;

    p {
      text-align: right;
      span {
        font-size: 1.3rem;
      }
    }
  }

  .shipping {
    font-weight: 200;
  }

  .shipping-address {
    margin-top: 2.5rem;
    padding: 0 2.5rem;

    font-style: italic;
    text-align: right;
  }

  .checkout-container {
    display: flex;
  }

  .checkout {
    width: 100%;
    margin: 2.5rem;
    padding: 1.5rem;

    background: var(--primary);
    color: #fff;
    font-size: 1.8rem;
    font-weight: 100;
    text-transform: uppercase;

    cursor: pointer;

    &:disabled {
      background: var(--lightGrey3);
    }
  }

  .loading {
    padding: 2rem;
    font-size: 2.4rem;
  }
`;

export const CartProductStyles = styled.li`
  padding: 2rem;
  .product {
    &__title {
      font-weight: 200;
    }
  }
`;
export const CartSkuStyles = styled.li`
  display: none;
  flex-direction: column;

  padding: 0.6rem 0;

  &.active {
    display: flex;
  }

  .sku-info {
    /* margin-left: 4.6rem; */
    display: grid;
    grid-template-columns: 10% 1fr 30%;
    gap: 1rem;
    justify-content: space-between;
    .close-icon {
      path {
        color: var(--grey);
      }
    }

    .delete {
      width: 1rem;
      justify-self: flex-end;
      cursor: pointer;
    }
  }

  .quantity-price {
    /* margin-left: 4.6rem; */
    display: grid;
    grid-template-columns: 10% 1fr 30%;
    gap: 1rem;

    .total-price {
      justify-self: flex-end;

      &.campaign {
        color: var(--blue);
        font-weight: bold;
      }
    }
  }
`;

export const CartWidgetStyles = styled.div`
  cursor: pointer;

  display: none;
  @media ${device.laptopL} {
    display: block;
  }
  position: relative;
  width: fit-content;
  svg {
    height: 3rem;
    width: 2.2rem !important;
 
    
    path {
      color: var(--grey);
    }
  }

  &.open {
    svg {
      path {
        color: var(--primary);
      }
    }
  }


  .count {
    position: relative;
    display: block;
    transition: all 0.4s;
    backface-visibility: hidden;
  }
  .count-enter {
    transform: rotateY(0.7turn) scale(2);
  }
  .count-enter-active {
    transform: rotateY(0);
  }
  .count-exit {
    top: 0;
    position: absolute;
    transform: rotateY(0);
  }
  .count-exit-active {
    transform: rotateY(0.7turn) scale(2);
  }
`;

export const Dot = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  position: absolute;
  right: -0.5rem;
  top: -1.5rem;
  text-align: center;
  line-height: 2.2rem;
  background: var(--primary);
  color: #fff;
  border-radius: 50%;
`;

export const InputSkuStyles = styled.form`
  margin-left: 4rem;
  height: 100%;
  display: grid;
  width: fit-content;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  position: relative;

  &.modal {
    display: flex;
  }

  &.table-item {
    width: 100%;
    display: flex;
    justify-items: center;
  }

  .tooltip {
    position: absolute;
    top: -60px; /* Increase the space above the input box */
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primary);
    color: white;
    padding: 8px 10px; /* Increase padding for better legibility */
    border-radius: 4px;
    font-size: 14px; /* Slightly larger font size for better readability */
    z-index: 100; /* Ensure the tooltip is above other elements */
    white-space: nowrap; /* Prevent the text from wrapping */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for better distinction from the background */
  }

  &.order-table {
    /* width: 100%;
    grid-template-columns: 1fr;

    input {
      margin-right: 1.5rem;
    } */
    /* display: flex; */
    .buttons-container {
      display: none;
    }
  }

  @media ${device.tablet} {
    margin-bottom: 0;
  }

  /* .buttons-container {
    display: flex;
  } */
  input {
    /* margin: 0 1rem; */
    padding: 0 0.5rem;
    width: 4rem;
    border: none;
    background: #eae9e9;
    outline: none;
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  .warning-icon {
    color: var(--orange);
  }

  .submit-button {
    &.modal {
      visibility: hidden;
    }
    svg {
      margin-left: 0 !important;
    }
  }
`;

export const QuantityButton = styled.div`
  /* min-width: 2.2rem; */
  width: fit-content;

  /* max-width: fit-content !important; */
  display: inline-flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background-color: #fff;
  color: #232323;
  /* border: 1px solid #232323; */
  margin: 0.5rem;
  padding: 0 0.2rem;
  border-radius: 10px;
  font-size: 1.6rem;
  font-family: monospace;

  -webkit-box-shadow: 2px 2px 1px 1px rgb(0 0 0 / 20%);
  -moz-box-shadow: 2px 2px 1px 1px rgb(0 0 0 / 20%);
  box-shadow: 2px 2px 1px 1px rgb(0 0 0 / 20%);

  &:nth-of-type(2) {
    margin-right: 0.5rem;
  }

  &:hover {
    border-color: #fe5000;
    color: #fe5000;
  }
  &:disabled {
    border-color: #acacac;
    color: #acacac;
  }
`;
