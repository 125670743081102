// import useCompany from '../lib/apollo/storage/appConfig/useCompany';

export default function formatMoney(cents, currency) {
  // const { currency } = useCompany();

  if (currency) {
    const formatter = Intl.NumberFormat('en-US', { style: 'currency', currency: currency || 'USD' });
    return formatter.format(cents);
  }
}


const formatPrice = (cents, currency) => {
  // console.log(currency)
  const formatter = Intl.NumberFormat('en-US', { style: 'currency', currency: currency || 'USD' });

  return formatter.format(cents);
}

export { formatPrice };