export const getTermsAndConditionsLink = (countryCode) => {
  const EU_COUNTRIES = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE','NO','CH','GB'];
  let pdfLink = '/pdf/NSD GTCs B2B 01-08 2023.pdf'; // Default to world

  if (EU_COUNTRIES.includes(countryCode)) {
      pdfLink = '/pdf/NSD GTCs B2B 01-08 2023.pdf';
  } else if (countryCode === 'US') {
      pdfLink = '/pdf/NSD GTCs B2B 01-08 2023.pdf';
  }

  return pdfLink;
}
