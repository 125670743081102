import React, { useState } from 'react';

// APOLLO
import useUser from '../../../lib/apollo/storage/appConfig/useUser';
import useCompany from '../../../lib/apollo/storage/appConfig/useCompany';

// COMPONENTS
import CartSku from '../../Cart/CartSku';
import OrderSku from '../../Order/OrderSku';

// TYPES
import { Sku } from '../../../types';

// UTILS
import { sizesOrder, sortSkus } from '../../../utils/sorting';
import formatMoney from '../../../utils/formatPrice';
import { getDiscountedPrice } from '../../../utils/getDiscountedPrice';

// STYLES
import { ProductWithSkusStyles } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

interface ProductWithSkusProps {
  productName: string;
  skus: Array<{ item: Sku }>;
  isOrder: boolean;
  isSentToVisma?: boolean;
  className: string;
  orderStatus: string;
  productId?: string;
}

const ProductWithSkus: React.FC<ProductWithSkusProps> = ({
  productName,
  skus,
  isOrder,
  isSentToVisma,
  className,
  orderStatus,
  productId
}) => {
  const [selectedSku, setSelectedSku] = useState(String);
  const { skus: sorted } = sortSkus([...skus]);
  const { currency } = useCompany();

  const proudctOpened = selectedSku === productName;

  let totalPriceProduct = 0;
  sorted.forEach((sku) => (totalPriceProduct += sku.price * sku.quantity));
  const totalPriceFormated = formatMoney(getDiscountedPrice(totalPriceProduct, true, sorted?.[0].discountedBy), currency);

  const { productsForCampaing } = useUser()
  const isInCampaign = productsForCampaing?.find((product) => product.id === productId)

  return (
    <ProductWithSkusStyles className={className}>
      {!isOrder ? (
        <div
          className="product__name"
          onClick={() => {
            if (proudctOpened) {
              setSelectedSku('');
            } else {
              setSelectedSku(productName);
            }
          }}
        >
          <FontAwesomeIcon
            width={15} height={15}
            icon={proudctOpened ? faMinusCircle : faPlusCircle}
          />
          <h3 className="product__title">{productName}</h3>
          {!proudctOpened && (
            <span className={`product__price ${isInCampaign ? 'campaing' : ''} `}>{totalPriceFormated}</span>
          )}
        </div>
      ) : (
        <h3 className="product__title">{productName}</h3>
      )}
      <ul className='mt-2'>
        {sorted
          .sort(
            (a, b) =>
              sizesOrder.findIndex((i) => i === a.item.size?.toLowerCase()) -
              sizesOrder.findIndex((i) => i === b.item.size?.toLowerCase())
          )
          .map(
            ({ item, quantity, price, orderItemId, quantityShipped, discountedBy }, index) => {

              return !isOrder ? (
                <CartSku
                  price={price}
                  key={`${item.id}-${index}`}
                  item={item}
                  quantity={quantity}
                  orderItemId={orderItemId}
                  productName={productName}
                  selectedSku={selectedSku}
                  discountedBy={discountedBy}
                />
              ) : (
                orderStatus !== 'COMPLETED' && (
                  <OrderSku
                    key={index}
                    item={item}
                    discountedBy={discountedBy}
                    quantity={quantity}
                    quantityShipped={quantityShipped}
                    price={price}
                    orderStatus={orderStatus}
                    currency={currency}
                  />
                )
              );
            },
          )}

        {orderStatus === 'COMPLETED' && (
          <>
            <div className="order-items-table header">
              <div>
                <p>#</p>
              </div>
              <div>
                <p>Quantity Ordered</p>
              </div>
              <div>
                <p>Quantity Shipped</p>
              </div>
            </div>
            {sorted.map(({ item, quantity, quantityShipped }, index) => (
              <div className="order-items-table" key={item.name}>
                <div>{index}</div>
                <div>
                  <p>
                    <span>{quantity}</span>x<span> {item.name}</span>
                  </p>
                </div>
                <div>
                  <p>
                    <span>{quantityShipped}</span>x<span> {item.name}</span>
                  </p>
                </div>
              </div>
            ))}
          </>
        )}
      </ul>
      {isOrder && (
        <div className="order-items-table order-items-table__price">{totalPriceFormated}</div>
      )}
    </ProductWithSkusStyles>
  );
};

export default ProductWithSkus;
