import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_ACTIVE_ORDER } from '../../queries';
import { activeOrderVar } from './apolloVars';

export default function useActiveOrder() {
const activeOrder = useReactiveVar(activeOrderVar);

  return {
    id: activeOrder?.id,
    shippingCost: activeOrder?.shippingCost,
    totalPrice: activeOrder?.totalPrice,
    totalVAT: activeOrder?.totalVAT,
    discount: activeOrder?.discount,
    vatRateOrder: activeOrder?.vatRateOrder,
    addActiveOrder(order) {

      activeOrderVar({
        id: order.id,
        shippingCost: order.shippingCost,
        totalPrice: order.totalPrice,
        totalVAT: order.totalVAT,
        discount: order.discount,
        vatRateOrder: order.vatRate,
      });
    },
  };
}
