import React from 'react';

// import SkuTotalPrice from '../Ui/Sku/SkuTotalPrice';

import { Sku } from '../../types';
import formatMoney from '@utils/formatPrice';
interface OrderSkuProps {
  item: Sku;
  quantity: number;
  quantityShipped: number;
  price: number;
  orderStatus: string;
  discountedBy: number;
  currency: string;
}

const OrderSku: React.FC<OrderSkuProps> = ({
  quantity,
  quantityShipped,
  item,
  orderStatus,
  discountedBy,
  currency,
  price
}) => {
  return (
    <div className='mt-2'>
      {orderStatus !== 'COMPLETED' ? (
        <p>
          <span>{quantity}</span>x<span> {item.name}</span>
        </p>
      ) : (
        <>
          <p>
            <span>Quantity Ordered: {quantity}</span>x<span> {item.name}</span>
          </p>
          <p>
            <span>Quantity Shipped: {quantityShipped}</span>x
            <span> {item.name}</span>
          </p>
        </>
      )}
      <>
        {discountedBy > 0 && (<p>
          <span>Discount: {discountedBy}%</span>
        </p>)}
        <p>
          <span> Price: {formatMoney(price, currency)}</span>
        </p>
      </>
      {/* <SkuTotalPrice
        className=""
        price={price}
        prices={item.prices}
        quantity={quantity}
      /> */}
    </div>
  );
};

export default OrderSku;
