

export const getDiscountedPrice = (baseAmount, applyDiscount, discount) => {
    
    const discountForUser = discount;
    // if (!discountForUser) {
    //     const { discount: receivedDiscount } = useCompany();
    //     discountForUser = receivedDiscount
    // }

    if (applyDiscount === false) return baseAmount

    const discountPercentage = discountForUser / 100;
    const discountAmount = baseAmount * discountPercentage
    
    const priceAfterDiscount = baseAmount - discountAmount;
    return priceAfterDiscount;
}