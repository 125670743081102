"use client"
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_USER } from "../../queries";
import { userVar } from "./apolloVars";
import { UserType } from "../../../../generated/graphql";

export default function useUser() {
  const user = useReactiveVar(userVar);
  // console.log(data, 'dataaa')

  return {
    id: user?.id,
    email: user?.email,
    productsForCampaing: user?.productsForCampaing,
    productLists: user?.productLists,
    homePageUrl: user?.homePageUrl,
    userType:user?.userType,
    customerType: user?.customerType,
    isEnrolledInTeachable: user?.isEnrolledInTeachable,
    priceListLink: user?.priceListLink,
    users: user?.users,
    addUser(user) {
      userVar({
        email: user.email,
        id: user.id,
        productsForCampaing: user.productsForCampaing,
        productLists: user.productLists,
        homePageUrl: user.homePageUrl,
        userType: user.userType,
        priceListLink: user.priceListLink,
        customerType: user.customerType,
        isEnrolledInTeachable: user.isEnrolledInTeachable,
        users: user.users,
      });
    },
  };
}
