"use client"
import styled from 'styled-components';

export const ProductWithSkusStyles = styled.li`
  .product {
    &__name {
      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-columns: 10% 1fr 30%;
      /* max-width: 80%; */
      
      gap: 1rem;
      cursor: pointer;
    }
    &__title {
      font-weight: 600;
      text-transform: capitalize;
      word-break: break-word;
    }

    &__price {
      text-align: right;

      &.campaing { 
        color: var(--blue);
        font-weight: bold;
      }
    }
  }

  .order-items-table {
    display: grid;
    gap: 1rem;
    grid-template-columns: 10% 1fr 40%;
    /* border: 1px solid var(--primary); */
    padding: 1rem;

    font-size: 1.4rem;

    &:first-of-type {
      
    }

    &:nth-of-type(odd) {
      background: var(--lightGrey);
    }


    &.header {
      margin-top: 2rem;
      font-weight: bold;
      background-color: var(--lightGrey3);
      padding: 1rem;
    }


    &__price {
      background-color: var(--lightGrey3) !important;
      
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
`;
