import _, { Dictionary } from "lodash";
import { Order, OrderItem } from "../generated/graphql";
import useActiveOrder from "../lib/apollo/storage/appConfig/useActiveOrder";
import useCartItems from "../lib/apollo/storage/appConfig/useCartItems";
// import useCompany from "../lib/apollo/storage/appConfig/useCompany";
// import { getDiscountedPrice } from "./getDiscountedPrice";

export const getTotalCartItems = () => {
  let totalItems = 0;

  // TODO: refactor names, remove not needed code, reuse code

  const { cartItems } = useCartItems();

  cartItems?.forEach((item) => {
    totalItems += item?.item?.quantity ?? 0;
  });
  return totalItems;
};

export const useCart = () => {
  const { cartItems } = useCartItems();
  const { shippingCost, totalPrice: totalCost, totalVAT } = useActiveOrder();

  // GROUP ITEMS BY PRODUCT NAME
  const items = _.groupBy(cartItems, "item.product.name");

  const grouped = { ...items };

  let lineItems: Dictionary<any[]> = {};
  const lineItemsArray = [];

  for (const key in grouped) {
    let formatedLineItem;
    grouped[key].map((lineItem: OrderItem) => {
      const price = lineItem.price;

      formatedLineItem = {
        skuName: lineItem?.item?.name,
        sku: lineItem?.item?.sku,
        productName: lineItem?.item?.product?.name,
        productId: lineItem?.item?.product?.id,
        quantity: lineItem?.quantity,
        discountedBy: lineItem?.discountedBy,
        price,
        item: lineItem?.item,
        inventory: lineItem?.item?.inventory,
        orderItemId: lineItem?.id,
      };

      // @ts-ignore
      if (lineItem?.item?.sku !== "0001") lineItemsArray.push(formatedLineItem);
    });
  }

  lineItems = _.groupBy(lineItemsArray, "productName");

  const productNames = Object.keys(lineItems);
  const skus: any[] = Object.values(lineItems);

  const formatedSkus = [];
  productNames.forEach((productName, index) => {
    // @ts-ignore
    formatedSkus.push({
      productName,
      skus: skus[index],
      productId: skus[index][0].productId,
    });
  });

  return {
    cart: cartItems || {},
    shippingCost: shippingCost,
    itemsByProduct: formatedSkus,
    totalPrice: totalCost,
  };
};

export const calculateTotalPrice = (price, quantity) => {
  return price * quantity;
};

export const getQuantityForSku = (id) => {
  const cart = useCart();
  let receivedQuanitity = 0;

  _.findIndex(cart?.itemsByProduct, (product) => {
    const match = _.findIndex(
      product.skus,
      (item: OrderItem) => item?.item?.id === id
    );
    if (match >= 0) {
      receivedQuanitity = product.skus[match].quantity;
    }
  });

  return receivedQuanitity;
};

export const getItemsByProduct = (items) => {
  const groupedItems = _.groupBy(items, "item.product.name");
  const grouped = { ...groupedItems };

  let lineItems: Dictionary<any[]> = {};
  const lineItemsArray = [];

  for (const key in grouped) {
    let formatedLineItem;
    grouped[key].map((lineItem: OrderItem) => {
      formatedLineItem = {
        skuName: lineItem?.item?.name,
        sku: lineItem?.item?.sku,
        productName: lineItem?.item?.product?.name,
        productId: lineItem?.item?.product?.id,
        quantity: lineItem.quantity,
        discountedBy: lineItem.discountedBy,
        quantityShipped: lineItem.quantityShipped,
        price: lineItem.price,
        item: lineItem.item,
      };

      // @ts-ignore
      if (lineItem?.item?.sku !== "0001") lineItemsArray.push(formatedLineItem);
    });
  }

  lineItems = _.groupBy(lineItemsArray, "productName");

  const productNames = Object.keys(lineItems);
  const skus = Object.values(lineItems);

  const formatedSkus = [];
  productNames.forEach((productName, index) => {
    // @ts-ignore
    formatedSkus.push({ productName, skus: skus[index] });
  });

  return formatedSkus;
};
