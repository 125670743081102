import React from 'react';
import useCompany from '../../../lib/apollo/storage/appConfig/useCompany';

// UTILS
import { calculateTotalPrice } from '../../../utils/cartUtils';
import formatMoney from '../../../utils/formatPrice';
import { getDiscountedPrice } from '@utils/getDiscountedPrice';

interface SkuTotalPriceProps {
  quantity: number;
  price: number;
  discountedBy: number;
  className: string;
}

const SkuTotalPrice: React.FC<SkuTotalPriceProps> = ({
  price,
  quantity,
  discountedBy,
  className,
}) => {
  const { currency } = useCompany();
  return (
    <div className={className}>
      {formatMoney(calculateTotalPrice(getDiscountedPrice(price, true, discountedBy), quantity), currency)}
    </div>
  );
};

export default SkuTotalPrice;
