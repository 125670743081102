import { useQuery, useReactiveVar } from '@apollo/client';

import { GET_CART_ITEMS } from '../../queries';
import { cartItemsVar } from './apolloVars';

export default function useCartItems() {
  const cartItems = useReactiveVar(cartItemsVar);

  return {
    cartItems: cartItems.cartItems,
    addCartItems(cartItems) { 
      cartItemsVar({ cartItems });
    },
  };
}
