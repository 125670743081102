import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_USER_STORE } from "../../queries";
import { storeVar } from "./apolloVars";

export default function useStore() {
  const data = useReactiveVar(storeVar);
  const store = data

  return {
    name: store?.name,
    address: store?.address,
    line_1: store?.line_1,
    city: store?.city,
    zipCode: store?.zipCode,
    country: store?.country,
    warehouseId: store?.warehouseId,
    external_ref: store?.external_ref,
    basePrice: store?.basePrice,
    id: store?.id,
    termsAndConditionsAccepted: store?.termsAndConditionsAccepted,
    users: store?.users,
    priority: store?.priority,
    customerDefinition: store?.customerDefinition,
    warehouseName: store?.warehouseName,
    currency: store?.currency,
    salesperson: store?.salesperson,
    status: store?.status,

    addStore({
      name,
      address,
      warehouseId,
      external_ref,
      basePrice,
      line_1,
      city,
      zipCode,
      country,
      id,
      termsAndConditionsAccepted,
      users,
      priority,
      customerDefinition,
      warehouseName,
      currency,
      salesperson,
      status
    }) {

      storeVar({
        name : name || store?.name, 
        address: address || store?.address,
        warehouseId: warehouseId || store?.warehouseId,
        country: country || store?.country,
        external_ref: external_ref || store?.external_ref,
        basePrice: basePrice || store?.basePrice,
        line_1: line_1 || store?.line_1,
        city: city || store?.city,
        zipCode : zipCode || store?.zipCode,
        id : id || store?.id,
        termsAndConditionsAccepted : termsAndConditionsAccepted || store?.termsAndConditionsAccepted,
        users : users || store?.users,
        priority : priority || store?.priority,
        customerDefinition : customerDefinition || store?.customerDefinition,
        warehouseName : warehouseName || store?.warehouseName,
        currency : currency || store?.currency,
        salesperson : salesperson || store?.salesperson,
        status: status || store?.status,
      });
    },
  };
}
