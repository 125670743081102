import _ from "lodash";

export const sizesOrder = [
  "shipping",
  "1-pate",
  "10-pate-2-extra",
  "small",
  "medium",
  "1",
  "2",
  "3",
  "4",
  "4.5",
  "4,5",
  "5",
  "5.5",
  "5,5",
  "6",
  "6.5",
  "6,5",
  "7",
  "7.5",
  "7,5",
  "8",
  "8.5",
  "8m5",
  "9",
  "9.5",
  "9,5",
  "10",
  "10.5",
  "10,5",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "76",
  "77",
  "78",
  "79",
  "80",
  "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "88",
  "89",
  "90",

  "1 m",
  "2 m",
  "1.0m",
  "2.0m",
  "3.0m",
  // '3 m',
  // '1 m',
  // '2 m',
  "1.2m/13mm",
  "1.2m/23mm",

  "1.5m/10mm",
  "1.5m/15mm",
  "1.7m/20mm",
  "2.0m/13mm",

  "2.0m/23mm",
  "2.8m/13mm",
  "2.8m/23mm",
  "3.8m/23mm",

  "10.0m",
  "15.0m",

  "one size/13mm",
  "one size/23mm",

  "S (37-40)",
  "M (40-43)",
  "L (43-46)",

  "2 m / 13 mm",
  "2 m / 23 mm",
  "2.8 m",
  "2.8 m / 13 mm",
  "2.8 m / 23 mm",
  "3 m",
  "3.8 m / 23 mm",
  "10 mm / 1.5 m",
  "15 mm / 1.5 m",
  "20 mm / 1.7 m",
  "50-ml",
  "100-ml",
  "300-ml",
  "500-ml",
  "junior",
  "original",
  "6xs",
  "6xs / 24",
  "5xs",
  "5xs / 27",
  "4xs",
  "4xs / 30",
  "3xs",
  "3xs / 33",
  "xxxs",
  "2xs",
  "2xs / 36",
  "xxs",
  "xs",
  "xs / 40",
  "s",
  "s / 45",
  "m",
  "m / 50",
  "l",
  "l / 55",
  "xl",
  "xl / 60",
  "2xl / 65",
  "3xl / 70",
  "4xl / 80",
  "5xl / 90",
  "xxl",
  "2xl",
  "xxxl",
  "3xl",
  "4xl",
  "5xl",
];

export const sortSkus = (skus) => {
  const sortByHarness = _.groupBy(skus, "harness");
  const sortByVersion = _.groupBy(skus, "version");
  const sortByColor = _.groupBy(skus, "color");

  const hasHarness = Object.keys(sortByHarness)[0] !== "";
  const hasVersion = Object.keys(sortByVersion)[0] !== "";
  const hasColor = Object.keys(sortByColor)[0] !== "";

  let sortedSkus = [];

  if (hasColor && !hasVersion && !hasHarness) {
    for (const key in sortByColor) {
      const sortBySize = sortSizes(sortByColor[key]);
      // @ts-ignore
      sortedSkus.push({
        skus: sortBySize,
        firstSortValue: key,
      });

      sortedSkus = sortedSkus.sort((a, b) =>
        // @ts-ignore
        a.skus[0].colorRank > b.skus[0].colorRank ? 1 : -1
      );
    }
  } else if (hasColor && hasVersion) {
    // Grouping by versions first
    for (const versionKey in sortByVersion) {
      // Grouping by color within each version
      const sortByColor = _.groupBy(sortByVersion[versionKey], "color");

      for (const colorKey in sortByColor) {
        const sortBySize = sortSizes(sortByColor[colorKey]);

        // @ts-ignore
        sortedSkus.push({
          skus: sortBySize,
          firstSortValue: `${versionKey} ${colorKey}`,
        });
      }
    }
  } else if (hasHarness && !hasVersion && !hasColor) {
    for (const harnessKey in sortByHarness) {
      const sortByColor = _.groupBy(sortByHarness[harnessKey], "color");

      for (const colorKey in sortByColor) {
        const sortBySize = sortSizes(sortByColor[colorKey]);

        // @ts-ignore
        sortedSkus.push({
          skus: sortBySize,
          firstSortValue: colorKey,
          secondSortValue: harnessKey,
        });
      }
    }
  } else if (hasVersion && !hasHarness && !hasColor) {
    for (const versionKey in sortByVersion) {
      const sortByColor = _.groupBy(sortByVersion[versionKey], "color");

      for (const colorKey in sortByColor) {
        const sortBySize = sortSizes(sortByColor[colorKey]);
        // @ts-ignore
        sortedSkus.push({
          skus: sortBySize,
          firstSortValue: versionKey,
          secondSortValue: colorKey,
        });
      }
    }
  } else if (hasHarness && hasVersion && !hasColor) {
    for (const harnessKey in sortByHarness) {
      // const sortByVersion = _.groupBy(sortByHarness[harnessKey], "version");

      const skusWithoutSize = skus.find((sku) => sku.size !== "");

      // for (let versionKey in sortByVersion) {
      const sortBySize = sortSizes(sortByColor[harnessKey]);
      // @ts-ignore
      sortedSkus.push({
        skus: sortBySize,
        noSize: skusWithoutSize === undefined,
        firstSortValue: harnessKey,
        // secondSortValue: versionKey
      });
      // }
    }
  } else {
    // @ts-ignore
    sortedSkus.push({
      skus: sortSizes(skus),
    });
  }

  return {
    sortedSkus,
    skus: skus?.length > 1 ? sortSizes(skus) : skus,
  };
};

function sortSizes(array) {
  const extractFirstNumber = (str) => {
    const match = str?.match(/\d+/);
    return match ? parseFloat(match?.[0]?.replace(",", ".")) : Infinity;
  };

  return [...array]?.sort((a, b) => {
    let aIndex = sizesOrder?.findIndex(
      (i) =>
        i.replace(",", ".").trim().toLowerCase() ===
        a.size?.replace(",", ".").trim().toLowerCase()
    );
    let bIndex = sizesOrder?.findIndex(
      (i) =>
        i.replace(",", ".").trim().toLowerCase() ===
        b.size?.replace(",", ".").trim().toLowerCase()
    );

    if (aIndex === -1) aIndex = extractFirstNumber(a.size);
    if (bIndex === -1) bIndex = extractFirstNumber(b.size);

    return aIndex - bIndex;
  });
}
// function sortSizes(array) {
//   const sizesOrder = [
//     'shipping',
//     // ... the rest of your sizesOrder array here
//   ];

//   const extractFirstNumber = (str) => {
//     const match = str.match(/\d+/);
//     return match ? parseFloat(match[0].replace(',', '.')) : Infinity;
//   };

//   return array.sort((a, b) => {
//     let aIndex = sizesOrder.findIndex((i) => i.replace(',', '.').toLowerCase() === a.size?.replace(',', '.').toLowerCase());
//     let bIndex = sizesOrder.findIndex((i) => i.replace(',', '.').toLowerCase() === b.size?.replace(',', '.').toLowerCase());

//     if(aIndex === -1) aIndex = extractFirstNumber(a.size);
//     if(bIndex === -1) bIndex = extractFirstNumber(b.size);

//     return aIndex - bIndex;
//   });
// }

// // export const sortProducts = (products) => {
// //   let srtProducts = [...products];

// //   srtProducts = srtProducts.sort((a, b) => {
// //     return a.lastWeekSales > b.lastWeekSales ? -1 : 1;
// //   });

// //   products.forEach((product) => {
// //     const outOfStock = product.skus.every(sku => sku.inventory === 0)
// //     if (outOfStock) {
// //       srtProducts.push(srtProducts.splice(srtProducts.indexOf(product), 1)[0]);
// //     }
// //   });

// //   const sortedProducts = [...srtProducts].sort((a, b) => {
// //     return (a.boosted === b.boosted) ? 0 : a.boosted ? -1 : 1;
// //   });

// //   return sortedProducts;

// // }
